<template>
  <div class="reset-password">
    <div class="login-logo">
      <router-link
          to="/"
          tag="img"
          :src="require(`@/assets/images/logo/logo-black.png`)"/>
    </div>
    <!-- reset-password section-->
    <section class="reset-password-section">
      <p>Enter the email address associated with your account and we'll send an email with instructions to reset your password.</p>
      <div class="auth-input">
        <label for="email">Email</label>
        <input type="email" id="email" placeholder="">
        <button type="submit">SEND INSTRUCTIONS</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  components: {},
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>